<template>
  <div class="app-container">
    <div style="width: 100%">
      <img src="../../assets/images/Project/u310_div.png" width="100%">
    </div>
    <div class="mt20">
      <view1/>
    </div>
    <div class="mt20">
      <bottom/>
    </div>
  </div>
</template>

<script>
import view1 from "@/views/Project/component/view1";
import bottom from "@/components/bottom";
export default {
  name: "index",
  components: {
    view1,
    bottom
  }
}
</script>

<style scoped>

</style>
