<template>
  <div class="project-view1-main">
    <div class="project-view1-content">
      <div class="project-view1-content-item" v-for="(item,index)  in showData" :key="index" @click="goPath(index)">
        <el-row>
          <el-col :span="20">
            <div class="project-view1-content-item-title">
              <div style="padding: 40px 60px" v-if="$i18n.locale == 'zh'">
                <span class="text1">{{item.title}}</span>
                <span class="text2">/</span>
                <span class="text3">{{item.title2}}</span>
              </div>
              <div style="padding: 40px 60px" v-else>
                <span class="text1">{{item.title2}}</span>
                <span class="text2">/</span>
                <span class="text3">{{item.title}}</span>
              </div>
              <div style="padding: 0px 60px">
                <span class="text2" v-if="$i18n.locale == 'zh'">{{item.info}}</span>
                <span class="text2" v-else>{{item.info2}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="project-view1-content-item-img">
              <img src="../../../assets/images/Project/01_u416.svg"/>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "view1",
  data() {
    return {
      active: 0,
      showData: [
        {
          title: '智慧养殖数智化解决方案',
          title2: 'DIoT Solution Of Intelligent Breeding',
          info: '简述：通过大数据计算、人工智能，不断的优化养殖参数、改进养殖工艺，推动智慧养殖，解决养殖效率低等行业痛点，为养殖场提供智慧养殖数智化解决方案… …',
          info2: 'Brief description: Through big data calculation and artificial intelligence, we constantly optimize breeding parameters, improve breeding technology, promote intelligent breeding, solve the pain points of low breeding efficiency, and provide intelligent breeding solutions for breeding farms',
        },
        {
          title: '数智化洗消烘干解决方案',
          title2: 'DIoT Cleaning & Stoving Solution',
          info: '简述：通过车辆类型识别、车牌信息采集，自动上报云平台，烘干过程一目了然，信息记录全面并问题追溯，为养殖场提供高效节能的洗消烘干解决方案… …',
          info2: 'Brief description: Through vehicle type recognition, license plate information collection, automatic report to the cloud platform, the drying process is clear,  the information is comprehensively recorded and the problem is traced, provid efficient and energy-saving decontamination and drying solutions for the farms.',
        },
        {
          title: 'IB2S智能楼房解决方案',
          title2: 'Intelligent Building Breeding Solution',
          info: '简述：通过采用集中通风模式，建立完整的生物资产安全体系，实现数据采集，实时远程监测，自动分析调控，达到养殖透明化，可视化… …',
          info2: 'Brief description: By adopting the centralized ventilation mode, a complete biological asset safety system is established, data collection, remote detection, automatic analysis and control are realized, and the aquaculture is transparent and visualized.',
        },
        {
          title: '集中报警解决方案',
          title2: 'Center Alarm Warn Solution',
          info: '简述：通过AI相机智能化信息采集、人工智能、大数据分析等技术，厂区内异常及时告警，日报周报自动推送，实现问题定位追溯… …',
          info2: 'Brief description: Through AI camera intelligent information collection, artificial intelligence, big data analysis and other technologies, abnormalities in the factory will be alerted in time, daily and weekly reports will be automatically pushed, and problem location and traceability can be realized',
        }
      ],
    }
  },
  methods: {
    getShow(val) {
      if (this.active == 0) {
        return true
      }else {
        return this.active == val
      }
    },
    goPath(val) {
      this.$router.push({path:'/Project-item',query: {id: val}})
    }
  }
}
</script>

<style lang="scss" scoped>
.project-view1-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .project-view1-content {
    width: 70%;
    .project-view1-content-item {
      background: rgba(247, 247, 247, 1);
      margin: 20px 0px;
      .project-view1-content-item-title {
        .text1 {
          font-size: 30px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
          font-weight: 650;
          font-style: normal;
          color: #00834b;
        }
        .text2 {
          font-size: 16px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
          font-weight: 650;
          font-style: normal;
        }
        .text3 {
          font-size: 16px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
          font-weight: 650;
          font-style: normal;
          color: #00834b;
        }
      }
      .project-view1-content-item-img {
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
